/* You can add global styles to this file, and also import other style files */
@use 'theme';

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

$primary-colors: theme.$app-primary-palette;
$primary-contrast: map-get($primary-colors, contrast);
$accent-colors: theme.$app-accent-palette;
$accent-contrast: map-get($accent-colors, contrast);
$font-family: theme.$font-family;

* {
  box-sizing: border-box;
}

/* universal styles for this app theme */

html,
body {
  width: 100%;
  height: 100%;
  color: theme.$primary-text-color;
  background-color: #f9f9f9;
  padding: 0;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

a,
a:hover,
a:visited {
  text-decoration: none;
  color: currentColor;
}

.hide {
  display: none !important;
}

a.mat-inline-button {
  cursor: pointer;
  height: 1.2em;
  padding: 0 10px !important;
  display: inline-block;

  &[color=primary] {
    color: map-get($primary-colors, 500);
    border-color: map-get($primary-colors, 500) !important;
  }

  &[color=accent] {
    color: map-get($accent-colors, 500);
    border-color: map-get($accent-colors, 500) !important;
  }
}

a[mat-button],
a[mat-flat-button],
a[mat-stroked-button],
button[mat-button],
button[mat-stroked-button],
button[mat-flat-button] {
  height: 52px;
  border-radius: 26px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.mat-inline-button {
    height: unset;
    border-radius: unset;
    padding: 0;
    display: inline-block;
    font-family: inherit;
    font-weight: 700;
    font-size: 18px;
    line-height: unset;

    &:not([disabled]) {
      &[color=primary] {
        color: map-get($primary-colors, 500);
      }

      &[color=accent] {
        color: map-get($accent-colors, 500);
      }
    }
  }

  &.mat-stroked-button {
    border-width: 2px;

    &:not([disabled]) {
      &[color=primary] {
        color: map-get($primary-colors, 500);
        border-color: map-get($primary-colors, 500) !important;
      }

      &[color=accent] {
        color: map-get($accent-colors, 500);
        border-color: map-get($accent-colors, 500) !important;
      }
    }
  }
}

[ng-reflect-router-link] {
  cursor: pointer;
}

// Centers the content vertically & horizontally
.center {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.v-center {
  display: flex;
  align-items: center;
}

.stroked-card {
  box-shadow: unset !important;
  border: map-get($primary-contrast, 600) 1px solid;
  padding: 20px;
  border-radius: 4px;

  > .header {
    border-bottom: 1px solid map-get($primary-contrast, 600);
    margin: -10px -20px;
    padding: 10px 20px;
  }
}

.cards-container {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.status-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > * {
    width: 350px;
  }
}

.status-card {
  position: relative;
  border: 1px solid map-get($primary-contrast, 600);
  border-radius: 4px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    background-color: #8b94b3;
    width: 4px;
    margin: -12px -16px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &.active::before {
    background-color: #6dd400;
  }

  &.error::before {
    background-color: #d40000;
  }

  &.in-progress::before {
    background-color: #2442f0;
  }
}

.layout-content {
  flex: 1;
  width: 1440px;
  align-self: center;
  background-color: #ffffff;
}

.layout-content.full-screen {
  width: 100% !important;
}

.spinner {
  margin: 0 auto;
}

.progress-bar {
  width: 100%;
  border-radius: 2px;
  height: 6px;
  border: 1px solid #8b94b3;
  margin: 4px 0;

  .progress {
    height: 100%;
    width: 0%;
    background-color: #8b94b3;
  }
}

.page-header {
  display: flex;
  align-items: center;

  > h1 {
    flex: 1;
  }
}

.form-inputs {
  display: flex;
  flex-direction: column;
}

.form-actions {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 20px;
}

.row {
  display: flex;
  gap: 10px;

  > *:not(.icons) {
    flex: 1;
  }

  .icons {
    display: flex;
    gap: 4px;
  }
}

mat-dialog-container,
.mat-dialog-container {
  border-radius: 8px !important;
}

.payapp-list-panel {
  margin-top: 38px !important;
  margin-left: 8px !important;
  width: 260px;
}

.payapp-sort-panel {
  margin-top: 40px !important;
  margin-left: 10px !important;
  width: 160px;
}

.payapp-status-panel {
  position: relative;
  top: 10px;
  left: -13px;
}

.payapp-datepicker-overlay .mat-datepicker-content {
  position: relative;
  top: 22px;
  left: -114px;
}


/** List styles **/
.list {
  > *:not(:last-child) {
    border-bottom: 1px solid #e9eCf4;
    margin: 40px 0;
    padding-bottom: 40px;
  }
}

.list-card {
  display: flex;
  padding-bottom: 4px;
  position: relative;
  gap: 20px;

  .avatar {
    max-width: 180px;
    max-height: 180px;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 6px;
    }
  }

  .body {
    flex: 1;
    padding: 0 10px;

    .mat-title {
      margin: 8px 0;
    }
  }

  .hover-icons {
    flex-direction: row;
    position: absolute;
    gap: 8px;
    z-index: 1;
    right: 0;
    top: 0;
    display: none;
  }

  &:hover {
    .hover-icons {
      display: flex;
    }
  }
}

/** /List styles **/


.headline-card {
  display: flex;
  gap: 20px;

  .avatar {
    display: inline;
    border-radius: 6px;
    max-width: 150px;
    max-height: 144px;
  }

  .body {
    flex: 1;
  }
}

.account-menu {
  min-width: 280px !important;
  max-width: none !important;
}

.quote {
  font-style: italic;
  position: relative;
  padding: 8px;
  margin-left: 12px;

  &::before {
    position: absolute;
    content: "";
    height: 100%;
    background-color: #8b94b3;
    width: 4px;
    margin: -8px;
  }
}

.panel-indicator {
  color: map-get($primary-colors, 500);
  cursor: pointer;

  &::after {
    content: "";
    transition: .33s ease-in-out;
    transform: rotate(45deg);
    display: inline-block;
    padding: 4px;
    margin-left: 10px;
    border-style: solid;
    border-bottom-width: 2px;
    border-right-width: 2px;
    border-left-width: 0;
    border-top-width: 0;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
  }

  &.open {
    &::after {
      transform: rotate(225deg);
    }
  }
}

.relative {
  position: relative;
}

.action-icons {
  flex-direction: row;
  position: absolute;
  gap: 8px;
  z-index: 1;
  right: 8px;
  top: 8px;
}

.full-width {
  width: 100%;
}

.link,
.mat-icon {
  cursor: pointer !important;
}

a.no-style {
  text-decoration: none;
  color: inherit;
  cursor: default;
}

a.no-style {
  text-decoration: none;
  color: inherit;
}

.preview-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  > * {
    position: relative;
    width: 120px;
    height: 120px;

    .hover-icon {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }

    &:hover {
      .hover-icon {
        position: absolute;
        display: block;
        top: 2px;
        right: 2px;
        z-index: 1;
        color: white;
        background-color: rgba(0, 0, 0, .2);
        border-radius: 50%;
      }
    }
  }

  &.small-icons {
    > * {
      width: 60px;
      height: 60px;
    }
  }
}

.dialog-actions {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;

  display: flex;
  flex-direction: row-reverse;
  gap: 10px;

  > * {
    background-color: white !important;
  }
}

.input-placeholder {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border: 1px dashed #8b94b3;
  border-radius: 4px;
  color: #60698b;
  font: normal 14px/22px Montserrat, sans-serif;
  cursor: pointer;

  &.small {
    align-items: center;
    justify-content: center;
  }
}

/* Timepicker can be used in modal */
.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}


/* sidebar dialog styles */
.sidebar {
  display: block;
  position: relative;

  margin: -24px;
  padding: 24px;

  mat-form-field {
    width: 100%;
  }

  .loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: unset;
  }

  [mat-dialog-close],
  .dialog-close,
  .dialog-actions {
    position: absolute;
    right: 24px;
    top: 24px;
    z-index: 1;
    background-color: white;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px 0;

    section {
      overflow: hidden;

      &.header {
        .annotation-preview {
          width: 100%;
          overflow: hidden;
          height: 200px;

          img {
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
            display: block;
          }
        }

        .title {
          font-size: 28px;
          line-height: 40px;
          font-weight: bold;
          padding: 8px 0;
        }
      }

      .section-label {
        font: 600 12px/15px 'Montserrat';
        color: #60698b;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
    }
  }
}

/* sidebar dialog styles */


.pad-l-20 {
  padding-left: 20px;
}

.line {
  display: flex;
  flex-direction: row;
  gap: 10px;
}


/** carousel styles **/
swiper {
  height: 100%;
  width: 100%;

  .swiper-pagination {
    background-color: #ffffff77;
  }

  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    .slide-header {
      position: absolute;
      z-index: 2;
      top: 0;
      right: 40px;
      display: flex;
      gap: 4px;
      align-items: center;
      justify-content: center;
    }

    .slide-content {
      flex: 1;
    }

    .slide-content,
    .slide-image {
      max-width: 100%;
      max-height: 90%;
    }
  }

  .swiper-lazy-loading {
    display: none;
  }
}

/** /carousel styles **/


/* missed typography */
.heading-3 {
  font: bold 18px/24px Montserrat, sans-serif;
}

@media (max-width: 767.98px) {
  .mat-headline {
    font: 600 22px/28px 'Montserrat';
  }

  .mat-title {
    font: 400 16px/20px 'Montserrat';
  }

  .mat-subheading-2 {
    font: 600 16px/20px 'Montserrat';
  }

  .mat-button,
  .mat-raised-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1279.98px) {
  .mat-headline {
    font: 600 24px/28px 'Montserrat';
  }

  .mat-title {
    font: 400 18px/22px 'Montserrat';
  }

  .mat-subheading-2 {
    font: 600 18px/22px 'Montserrat';
  }

  .mat-button,
  .mat-raised-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    font-size: 18px;
  }
}

/* missed typography */

.tags {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;

  .tag {
    padding: 0 6px;
    border-radius: 4px;
    background-color: map-get(theme.$primary-contrast, 600);
  }
}

.tag {
  padding: 0 6px;
  border-radius: 4px;
  background-color: map-get($primary-contrast, 600);
}

/* facet styles */
.filters {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .facet {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .facet-title {
      color: theme.$secondary-text-color;
      font: 700 16px/18px 'Montserrat';
      text-transform: uppercase;
    }

    .facet-body {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .facet-radio {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      .mat-checkbox .mat-checkbox-layout,
      .mat-radio-label {
        gap: 10px;
      }

      .mat-subheading-1 {
        margin: 4px 0;
      }
    }

  }
}

/* /facet styles */


.accent {
  color: map-get($accent-colors, 500);
}

.primary {
  color: map-get($primary-colors, 500);
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: map-get($primary-contrast, 600) 1px solid;
  margin: 10px 0;
  line-height: 1px;

  span {
    color: theme.$secondary-text-color;
    background: white;
    padding: 0 20px;
  }
}

.limit-text {
  height: 70px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.multiline-text {
  word-wrap: break-word;
  white-space: pre-line;
}

.alert {
  padding: 20px 30px;
  font: 500 18px/24px 'Montserrat';
  border-radius: 4px;
  color: #3757a0;
  border: 1px solid #bfcbfa;
  background-color: #c8d4f0;

  &.error {
    color: #d40000;
    border: 1px solid #faa0a0;
    background-color: #fcdcdc;

  }
}

.logo {
  display: block;
  cursor: pointer;
  background-image: url("https://www.sitenotesapp.com/assets/logo-2.png");
  background-repeat: no-repeat;
  background-size: 122px 20px;
  width: 122px;
  height: 20px;
}


/* table styles */
/* tabular-card */
.tabular-card {
  width: 100%;
  display: block;
  border: 1px solid #e9ecf4;
  border-radius: 6px;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 20px 10px;
    border-bottom: 1px solid #e9ecf4;

    .title {
      font: 600 18px/24px 'Montserrat';
      flex: 1;
    }
  }

  .body {

    .content {
      padding: 20px 10px;
    }

    table {
      width: 100%;
      border-collapse: collapse;
      font: 400 16px/22px 'Montserrat';

      thead {

        tr {
          border-bottom: 1px solid #e9ecf4;
          background-color: #eaeaea;

          th {
            font-weight: 500;
            text-align: left;
            padding: 20px 10px;
          }
        }
      }

      tbody {
        tr + tr:not(.continue) {
          border-top: 1px solid #e9ecf4;
        }

        tr {
          cursor: pointer;

          td {
            padding: 20px 10px;

            a {
              font-weight: 500;
              font-size: 14px;
              color: map-get($primary-colors, 500);
            }
          }
        }
      }
    }
  }

}

.multi-row {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ruler-down {
  border-bottom: 1px solid #e9ecf4;
}

$status-colors: (
  "active": rgb(2, 209, 140),
  "completed": rgb(2, 209, 140),
  "info": rgb(0, 140, 255),
  "danger": rgb(240, 76, 26),
  "warn": rgb(246, 177, 66)
);

@each $status,
$color in $status-colors {
  .color-#{$status} {
    color: $color;
  }
}

.color-green {
  color: green;
}

/* /table styles */


/* timeline */
.timeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 10px 15px;

  .event + .event {
    border-top: 1px solid #e9ecf4;
  }

  .event {
    padding: 10px 0;
    display: flex;
    gap: 10px;

    .date {
      display: flex;
      flex-direction: column;
      gap: 4px;

      .month {
        font-weight: 400;
        color: map-get($primary-colors, 'A400');
      }

      .day {
        font-weight: 600;
        color: map-get($primary-colors, 400);
        display: flex;
        justify-content: center;
        align-content: center;
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }


}

/* /timeline */
.mat-option {
  &.large {
    height: unset !important;
    padding: 16px;
  }
}

.mat-caption-strong {
  font-weight: 600;
}

/* drag-n-drop css */
/*
  temp fix for bug:
  https://github.com/angular/components/issues/14280
*/
.cdk-global-scrollblock {
  top: 0 !important;
}

[cdkDrag] {
  cursor: pointer;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

/* /drag-n-drop css */


.muted {
  color: #8b94b3 !important;
  text-transform: capitalize;
  font-style: italic;
}


.icon {
  display: inline-block;

  .badge {
    display: inline-block;
    border-radius: 4px;
    padding: 0 4px;
    margin-left: -14px;

    overflow: hidden;
    text-align: center;
    font-family: $font-family;
    font-size: 12px;
    line-height: 24px;
    min-width: 24px;
    font-weight: bold;
    white-space: nowrap;

    &[color=accent] {
      background-color: map-get($accent-colors, 600);
      color: map-get($accent-colors, 50);
    }

    &[color=primary] {
      background-color: map-get($primary-colors, 600);
      color: map-get($primary-colors, 50);
    }
  }

}

.tab-content-large {
  padding-top: 20px;

  mat-form-field {
    width: 100%;
  }

  .container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 20px 0;

    section {
      overflow: hidden;

      &.header {
        .title {
          font-size: 28px;
          line-height: 40px;
          font-weight: bold;
          padding: 8px 0;
        }
      }

      .section-label {
        font: 600 12px/15px 'Montserrat';
        color: #60698b;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
    }
  }
}

[hoverable] {
  [hover-toggle] {
    display: none;
    width: 0px;
  }

  &:hover {
    [hover-toggle] {
      // display: inline-block;
      // width: 100%;
      // font-family: $font-family;
      // font-weight: normal;
      // text-align: center;
      // font-size: 20px;
      // line-height: 24px;
      // padding: 0 4px;
      // white-space: nowrap;
    }
  }
}

.stripe-left {
  &:before {
    content: attr(stripe-text);
    color: white;
    font-weight: 600;
    line-height: 1.66em;
    overflow: hidden;
    text-align: center;
    letter-spacing: 0.33em;
    writing-mode: vertical-lr;
    transform: rotate(-180deg);
    background: repeating-linear-gradient(-55deg,
      #202020bc,
      #202020bc 10px,
      #2d2d2dbc 10px,
      #2d2d2dbc 20px);
  }
}

/* sidebar tabs */
.mat-mdc-list-item-icon {
  color: rgba(0, 0, 0, 0.54);
}

.left-tab-viewer {
  display: flex;
  gap: 20px;

  .list {
    width: 20%;
    margin-left: -16px;
    margin-top: -8px;

    .mat-list-single-selected-option {
      background: map-get($accent-colors, 50);
      position: relative;

      &:after {
        content: " ";
        position: absolute;
        background-color: map-get($accent-colors, 100);
        width: 4px;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
  }

  .content {
    flex: 1;
  }
}

/* /sidebar tabs */


/* toolbar */
.toolbar {
  &[mat-align-tabs=center] {
    .mat-tab-header {
      .mat-tab-labels {
        justify-content: space-evenly;
      }
    }
  }

  .mat-tab-header {
    border-top: none;

    .mat-ink-bar {
      display: none;
    }

    .mat-tab-labels {

      // used to toggle icons
      .mat-tab-label:first-child {
        display: none;
      }

      .mat-tab-label {
        &.mat-tab-label-active {
          color: map-get($primary-colors, 500) !important;
        }
      }
    }
  }

  .mat-body-content {
    overflow: hidden;
  }
}

/* /toolbar */

/* v-cards */
.v-cards {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card {
  border: map-get(theme.$primary-contrast, 600) 1px solid;
  border-radius: 4px;
  padding: 12px 16px;

  .mat-subheading-2 {
    font-size: 18px;
    font-weight: 700;
  }

  .sub-item {
    padding: 14px 0;
  }

  .sub-item + .sub-item {
    border-top: 1px solid map-get(theme.$primary-contrast, 600);
  }
}

/* /v-cards */

/* hint styles for custom fields */
.custom-hints {
  .mat-form-field-underline {
    background-color: map-get($accent-colors, 500);
  }

  .hint-icon {
    color: map-get($accent-colors, 500);
  }

  .mat-form-field-subscript-wrapper {
    margin-top: 0;

    .mat-hint {
      color: map-get($accent-colors, 800);
      background-color: map-get($accent-colors, 50);
      padding: 10px;
    }

    .mat-form-field-hint-spacer {
      display: none;
    }
  }
}

/* /hint styles for custom fields */

.mat-tab-group.vertical-tabs {
  flex-direction: row !important;
  gap: 20px;

  > .mat-tab-header {
    .mat-tab-labels {
      flex-direction: column !important;
      gap: 0;

      .mat-tab-label {
        padding: 24px 10px;
        justify-content: flex-start;
        background: map-get($accent-colors, 50);
        border-right: 4px solid map-get($accent-colors, 100);

        &.mat-tab-label-active {
          background: map-get($accent-colors, 100);
          border-right: 4px solid map-get($accent-colors, 200);
        }
      }
    }

    .mat-ink-bar {
      display: none;
    }
  }

  > .mat-tab-body-wrapper {
    width: 100%;
  }
}

.component-loader-bar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.loader-panel .mat-dialog-container {
  padding: 0;
}

.mat-datetimepicker-calendar-header {
  background: #FFFFFF !important;
  color: #000000 !important;
}

.floating-dialog-box .mat-dialog-container {
  border-radius: 10px !important;
}

.mat-snack-bar-container {
  border-radius: 50px !important;
}

.mat-snack-bar-container.success .mat-simple-snack-bar-content:before {
  content: '✓';
  padding-right: 10px;
}

.mat-snack-bar-container.danger .mat-simple-snack-bar-content:before {
  content: '𐄂';
  padding-right: 10px;
}

.mat-snack-bar-top {
  margin-top: 150px !important;
}

.mat-snack-bar-container .mat-simple-snackbar span {
  font-size: 17px !important;
  word-break: break-all;
}

.mat-simple-snackbar {
  padding: 0 5px !important;
}

//.mat-snack-bar-container.danger {
//  background: rgba(255, 0, 0, 1) !important;
//}
//
//.mat-snack-bar-container.warning {
//  background: rgba(253, 178, 31, 1) !important;
//}
//
//.mat-snack-bar-container.success {
//  background: rgba(60, 179, 113, 1) !important;
//}

.mat-datepicker-content {
  border-radius: 10px;
}

.mat-calendar {
  height: auto !important;
  width: 391px !important;
  box-shadow: 2px 4px 12px 0 #C3C7D5;
  border-radius: 10px;

  .mat-calendar-table-header {
    tr {
      th {
        font-size: 12px;
        font-weight: 600;
        line-height: 14.63px;
        color: #60698B;
      }
    }
  }

  .mat-calendar-controls {
    margin: 0 0 28px 0;
  }

  .mat-mdc-button-persistent-ripple,
  .mat-ripple,
  .mat-calendar-arrow {
    display: none;
  }

  .mat-calendar-table-header-divider {
    display: none;
  }

  .mat-calendar-body-label {
    opacity: 0;
  }

  .mat-calendar-body-label[colspan="7"] {
    display: none;
  }

  .mat-calendar-period-button {
    padding: 0;
  }

  .mat-calendar-body-cell-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #060B1E;
  }

  .mat-calendar-body-today {
    background-color: transparent !important;
    border-radius: 0;
    border: none;
    color: #1036D6;
    font-weight: 600;
  }

  .mat-calendar-body-cell:hover .mat-calendar-body-cell-content,
  .mat-calendar-body-selected {
    background-color: transparent !important;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #1036D6;
    font-weight: 700;
  }

}


#main-nav {
  margin-bottom: unset !important;
}
