// Variables
$regal-blue: #034378;
$san-juan: #2d4e68;
$bermuda: #77d7b9;
$white: #fff;
$black: #000;


@mixin cf {

  &::before,
  &::after {
    content: '';
    display: table;
  }

  &::after {
    clear: both;
  }
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

// Base styles
.app-card {

  width: 350px;

  .menu-content {
    @include cf;
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      display: inline-block;
    }

    a {
      color: $white;
    }

    span {
      position: absolute;
      left: 50%;
      top: 0;
      font-size: 10px;
      font-weight: 700;
      font-family: 'Open Sans';
      transform: translate(-50%, 0);
    }
  }

  .wrapper {
    background-color: lightslategrey;
    min-height: 350px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba($black, 0.3), 0 4px 8px rgba($black, 0.2);

    &:hover {
      .data {
        transform: translateY(20px);
      }
    }
  }

  .data {
    position: absolute;
    bottom: 0;
    width: 100%;
    transform: translateY(calc(70px + 1em));
    transition: transform 0.3s;

    .content {
      padding: 1em;
      position: relative;
      z-index: 1;
    }
  }

  .author {
    font-size: 12px;
  }

  .title {
    margin-top: 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .text {
    height: 70px;
    margin: 0;
  }

  .menu-content.show {
    transform: translateY(-60px);
  }
}

// First card styles
.card-1 {
  .date {
    position: absolute;
    top: 0;
    left: 0;
    background-color: $bermuda;
    color: $white;
    padding: 0.8em;

    span {
      display: block;
      text-align: center;
    }

    .day {
      font-weight: 700;
      font-size: 24px;
      text-shadow: 2px 3px 2px rgba($black, 0.18);
    }

    .month {
      text-transform: uppercase;
    }

    .month,
    .year {
      font-size: 12px;
    }
  }

  .content {
    background-color: $white;
    box-shadow: 0 5px 30px 10px rgba($black, 0.3);
  }

  .title {
    a {
      color: lighten($black, 50%);
    }
  }

  .menu-button {
    position: absolute;
    z-index: 999;
    top: 16px;
    right: 16px;
    width: 25px;
    text-align: center;
    cursor: pointer;

    span {
      width: 5px;
      height: 5px;
      background-color: lighten($black, 50%);
      color: lighten($black, 50%);
      position: relative;
      display: inline-block;
      border-radius: 50%;

      &::after,
      &::before {
        content: '';
        display: block;
        width: 5px;
        height: 5px;
        background-color: currentColor;
        position: absolute;
        border-radius: 50%;
      }

      &::before {
        left: -10px;
      }

      &::after {
        right: -10px;
      }
    }
  }

  .menu-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: -1;
    transition: transform 0.3s;
    transform: translateY(0);
    display: flex;
    justify-content: space-around;
    background-color: $bermuda;

    .menu-item {
      height: inherit;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      padding: 0 8px;
    }

  }

}

// Second card styles
.card-2 {
  .wrapper {
    &:hover {
      .menu-content {
        span {
          transform: translate(-50%, -10px);
          opacity: 1;
        }
      }
    }
  }

  .header {
    @include cf;
    color: $white;
    padding: 1em;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 99;

    .date {
      float: left;
      font-size: 12px;
    }
  }

  .menu-content {
    float: right;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: center;
    align-items: center;

    .menu-item {
      height: inherit;
      font-size: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
      height: 40px;
      width: 40px;
    }


  }

  .data {
    color: $white;
    transform: translateY(calc(70px + 4em));
  }

  .title {
    a {
      color: $white;
    }
  }

  .button {
    display: block;
    width: 100px;
    margin: 4em auto 2em;
    text-align: center;
    font-size: 12px;
    color: $black;
    line-height: 2;
    position: relative;
    font-weight: 700;
    cursor: pointer;
    background-color: $white;

    &::after {
      content: '\2192';
      opacity: 0;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 0.3s;
    }

    &:hover {
      &::after {
        transform: translate(5px, -50%);
        opacity: 1;
      }
    }
  }
}
