.text-type-1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.88px;
  color: #060b1e;
}

.text-type-2 {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  color: #060b1e;
}

.text-type-3 {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  color: #60698b;
}

.text-type-4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 300;
  line-height: 24.38px;
  color: #060b1e;
}

.text-type-5 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #060b1e;
}

.text-type-6 {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  color: #060b1e;
}

.text-type-7 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.5px;
  color: #1036d6;
}

.text-type-8 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #060b1e;
}

.text-type-9 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #1036d6;
}

.text-type-10 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #60698b;
}

.text-type-11 {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #060b1e;
}

.text-type-12 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  color: #8a6500;
}

.text-type-13 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 17.07px;
  color: #60698b;
}

.text-type-14 {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #60698b;
}

.text-type-15 {
  font-family: 'Montserrat', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 14.63px;
  color: #60698b;
}

.project-details-page-status-filter-popup {
  background: #FFFFFF;
  margin-top: 5px;
  max-width: unset !important;
  box-shadow: 2px 4px 12px 0 rgba(195, 199, 213, 1);
  border-radius: 5px;
  width: 320px;

  .search-section {
    padding: 0 20px 20px 20px;
  }

  .search {
    display: flex;
    flex-direction: row;
    border: 1px solid #8B94B3;
    border-radius: 25px;
    padding: 0 14px;
    height: 44px;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
  }

  .search .mat-icon {
    height: 20px;
    width: 20px;
  }

  .mat-tab-body {
    max-height: 250px;
    overflow: auto;
  }

  .mat-tab-label-content {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    color: #060B1E;
    padding-bottom: 4px;
  }

  .mat-tab-label-active .mat-tab-label-content {
    font-weight: 600;
  }

  .mat-checkbox {
    margin-bottom: 20px;
    height: unset;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0 20px;
  }

  .mat-checkbox-layout {
    white-space: normal;
  }

  .mat-checkbox-inner-container {
    height: 24px;
    width: 24px;
  }

  .mat-checkbox-inner-container {
    margin-right: 12px;
  }

  .mat-checkbox-frame {
    border-color: #60698B;
    border-radius: 3px;
  }

  .mat-checkbox-label {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    color: #060B1E;
  }

  .mat-checkbox-ripple {
    display: none;
  }

  .mat-tab-header {
    padding: 20px 16px;
  }
}

.change-order-filter-popup {
  background: #FFFFFF;
  margin-top: 5px;
  max-width: unset !important;
  box-shadow: 2px 4px 12px 0 rgba(195, 199, 213, 1);
  border-radius: 5px;
  width: 320px;

  .change-order-filter-list {
    max-height: 214px;
    overflow: auto;
    padding: 4px 16px;
  }

  .change-order-filter-item {
    height: 60px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom: 2px solid #E9ECF4;
  }

  .change-order-filter-item:last-child {
    border-bottom: none;
  }
}

.site-status-filter-content {
  background: #FFFFFF;
}

.highlighted-value {
  background-color: #BDC9FF;
  margin-bottom: 4px;
  padding: 0 5px;
}


.daily-report-menu {
  margin-top: 2px;
  background: #FFFFFF;
  box-shadow: 2px 4px 12px 0 #C3C7D5;
  width: 280px;
  padding: 8px 16px;
  border-radius: 7px;
  height: 270px;
  overflow: auto;

  .each-menu-option {
    border-top: 1px solid #E9ECF4;
    border-bottom: 1px solid #E9ECF4;

    &.first {
      border-top: none;
    }

    &.last {
      border-bottom: none;
    }
  }
}

.status-menu-panel {
  min-width: 175px !important;
}

.mat-select-panel {
  margin-top: 25px !important;
  margin-left: 17px !important;
}
