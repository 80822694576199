@use '@angular/material' as mat;
@import '@angular/material/theming';

$app-primary-palette: (
  50 : #e2e7fa,
  100 : #b7c3f3,
  200 : #889beb,
  300 : #5872e2,
  400 : #3454dc,
  500 : #1036d6,
  600 : #0e30d1,
  700 : #0c29cc,
  800 : #0922c6,
  900 : #0516bc,
  A100 : #e6e7ff,
  A200 : #b3b7ff,
  A400 : #8087ff,
  A700 : #666fff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #e9ecf4,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  )
);

$app-accent-palette: mat.$amber-palette;
$font-family: 'Montserrat', sans-serif;

$app-typography: mat.define-legacy-typography-config($font-family: "'Montserrat', sans-serif",
    $display-1: mat.define-typography-level(36px, 44px, 700),
    $headline: mat.define-typography-level(28px, 34px, 700),
    $title: mat.define-typography-level(16px, 24px, 400),
    $subheading-2: mat.define-typography-level(16px, 24px, 600),
    $subheading-1: mat.define-typography-level(12px, 15px, 600),
    $body-1: mat.define-typography-level(16px, 24px, 400),
    $body-2: mat.define-typography-level(16px, 24px, 500),
    $caption: mat.define-typography-level(14px, 22px, 400),
    $button: mat.define-typography-level(18px, 20px, 700));

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($app-typography);`
@include mat.all-legacy-component-typographies($app-typography);
@include mat.legacy-core();

$app-primary: mat.define-palette($app-primary-palette);
$app-accent: mat.define-palette($app-accent-palette, A200, A100, A400);
$app-warn: mat.define-palette(mat.$red-palette);

$app-theme: mat.define-light-theme((color: (primary: $app-primary,
        accent: $app-accent,
        warn: $app-warn,
      )));

@include mat.all-legacy-component-themes($app-theme);

/* typography margins, colors */
$primary-text-color: #060b1e;
$secondary-text-color: #60698b;
$primary-contrast: map-get($app-primary-palette, contrast);

.mat-display-1 {
  margin: 0 0 36px !important;
}

.h1,
.mat-h1,
.mat-headline,
.h2,
.mat-h2,
.mat-title,
.h3,
.mat-h3,
.mat-subheading-2,
.h4,
.mat-h4,
.mat-subheading-1 {
  margin: 0;
}

.h2,
.mat-h2,
.mat-title,
.h4,
.mat-h4,
.mat-subheading-1,
.mat-caption {
  color: $secondary-text-color;
}

.h4,
.mat-h4,
.mat-subheading-1 {
  text-transform: uppercase;
  margin: 12px 0;
  letter-spacing: 0.066em;
}

/* /typography margins, colors */

/* tabs */
.sub-nav {

  &.mat-tab-nav-bar,
  &.mat-tab-header {
    border: none;
  }

  .mat-tab-links {
    display: flex;
    gap: 40px;

    .mat-tab-link {
      padding: 0;
      min-width: 0 !important;
      font-weight: 300;
      font-size: 20px;
      opacity: 1 !important;
      color: $secondary-text-color;
      height: 30px;

      &[active],
      &.mat-tab-label-active {
        font-weight: 600;
        color: $primary-text-color;
      }
    }
  }
}

/** Todo Refactor above and below tabs styling */
.mat-tab-group {
  .mat-tab-header {
    border-bottom: none;
  }

  .mat-tab-labels {
    display: flex;
    gap: 40px;

    .mat-tab-label {
      font-weight: 300;
      font-size: 20px;
      opacity: 1;
      color: $secondary-text-color;
      height: 30px;
      min-width: 24px;
      padding: 0;

      &.mat-tab-label-active {
        font-weight: 600;
        color: $primary-text-color;
      }
    }
  }
}

/* /tabs */

/* expansion-panels */
.mat-accordion {

  .mat-expansion-panel,
  mat-expansion-panel {
    box-shadow: unset;
    border-radius: 0;
    border-bottom: map-get($primary-contrast, 600) 1px solid;

    &.mat-expansion-panel-spacing {
      margin: 0;
    }

    &:first-of-type {
      border-top-right-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }

    &:last-of-type {
      border-bottom-right-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }

  .mat-expansion-panel-header,
  mat-expansion-panel-header {
    padding: 0;
    position: relative;

    .mat-expansion-indicator {
      position: absolute;
      right: 4px;
      padding: 8px;

      &::after {
        color: $primary-text-color;
      }
    }
  }

  .mat-expansion-panel-body {
    padding: 0 0 16px;
  }
}

.mat-accordion.consolidated-report {

  .mat-expansion-panel-header,
  mat-expansion-panel-header {
    position: relative;
    height: 90px;
    padding: 0;

    .mat-content {
      display: flex;
      align-items: center;
      gap: 20px;
      min-height: 48px;

      mat-icon {
        font-size: 48px;
        color: map-get($app-primary-palette, 600);
      }
    }
  }
}

/* /expansion-panels */

.mat-checkbox {
  &.check-list {
    .mat-checkbox-label {
      padding: 10px 20px !important;
    }
  }
}

.capitalize {
  text-transform: capitalize;
}


.line-items {

  .mat-expansion-panel-header {
    .header-row{
      display: flex;
      align-items: center;
      padding-right: 20px;
      width: 100%;


      >*:not(.icons) {
        flex: 1;
        display: flex;
        gap: 10px;
      }

      .icons {
        display: flex;
        gap: 4px;
      }
    }

    mat-icon {
      &[color=warn] {
        color: map-get($app-warn, 600);
      }
    }
  }
}
